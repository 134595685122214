import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { StoryData } from 'storyblok-js-client';
import Layout from '../../sb-components/layout';
import { CommonData, GlobalData } from '../../common-types';
import StoryblokService from '../../utils/storyblok-service';
import BlogContext from '../../sb-components/blogContext';
import Page from '../../sb-components/page';
import { getEnvironment } from '../../utils/helpers';

interface State {
  pageData?: CommonData;
  globalData: GlobalData;
  path: string;
  blogs?: any;
  authors?: any;
}

interface Props
  extends PageProps<{
    pageData: StoryData<string>;
    globalData: StoryData<string>;
    blogs?: any;
    authors?: any;
  }> {}

class PostPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const authorsArray = props.data.authors.edges.map((item: any) => ({
      ...item.node,
    }));
    const authorsDict = Object.assign(
      {},
      ...authorsArray.map((item: any) => ({
        [item.uuid]: { ...item, content: JSON.parse(item.content) },
      }))
    );

    const pageContext = this.props.pageContext as any;

    this.state = {
      globalData: JSON.parse(props.data.globalData.content),
      path: props.path,
      blogs: props.data.blogs.edges.map((item: any) => ({
        ...item.node,
        content: JSON.parse(item.node.content),
      })),
      pageData: pageContext?.story?.content,
      authors: authorsDict,
    };
  }

  async componentDidMount() {
    try {
      const response = await StoryblokService.get(
        `cdn/stories/${this.props.location.pathname}`,
        {
          version: getEnvironment() !== 'PROD' ? 'draft' : '',
        }
      );
      this.setState((prevState) => ({
        ...prevState,
        pageData: { ...response.data.story.content },
      }));
      setTimeout(() => StoryblokService.initEditor(this), 200);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    try {
      const response = await StoryblokService.get(
        `cdn/stories/?starts_with=blog`
      );

      const newItems = response?.data?.stories.filter(
        (a: any) => a.content.component === 'post'
      );

      if (newItems && newItems?.length) {
        this.setState((prevState) => ({
          ...prevState,
          blogs: [...Object.values(newItems)],
        }));
      }
      setTimeout(() => StoryblokService.initEditor(this), 200);
      this.setState((prevState) => ({
        ...prevState,
      }));
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      this.setState((prevState) => ({
        ...prevState,
      }));
    }
  }

  render() {
    const initialContent = { body: [] };
    const pageContext = this.props.pageContext as any;
    const path = pageContext?.story?.full_slug;
    const contentString = pageContext?.story?.content;
    const content =
      (contentString && JSON.parse(contentString)) || initialContent;
    const seoMeta = content?.seoMeta;

    return (
      <BlogContext.Provider
        value={{ blogs: this.state.blogs, authors: this.state.authors }}
      >
        <Layout
          className="page page-blogPost"
          header={this.state.globalData.header}
          footer={this.state.globalData.footer}
          showBlock
          cookiesConsent={this.state.globalData.cookiesConsent}
          full_slug={this.state.path.replace(/(\/)/, '')}
          path={`/${path}`}
        >
          <Page
            blok={{
              ...this.state.pageData,
              seoMeta: this.state?.pageData?.seoMeta || seoMeta,
            }}
          />
        </Layout>
      </BlogContext.Provider>
    );
  }
}

export default PostPage;

export const query = graphql`
  {
    pageData: storyblokEntry(slug: { eq: "blogPost" }) {
      name
      content
      full_slug
      uuid
    }
    globalData: storyblokEntry(full_slug: { eq: "global" }) {
      name
      content
      full_slug
      uuid
    }
    blogs: allStoryblokEntry(filter: { field_component: { eq: "post" } }) {
      edges {
        node {
          id
          name
          slug
          field_component
          full_slug
          content
          tag_list
          uuid
        }
      }
    }
    authors: allStoryblokEntry(filter: { field_component: { eq: "author" } }) {
      edges {
        node {
          id
          full_slug
          uuid
          content
          name
        }
      }
    }
  }
`;
