import React, { FC } from 'react';
import { storyblokEditable } from '@storyblok/react';
import DynamicComponent from './dynamicComponent';
import MetaTags from './meta-tags/meta-tags.component';

export interface Props {
  blok: any;
}

const Page: FC<Props> = ({ blok }) => {
  const content = blok.body?.map((childBlok: any) => (
    <DynamicComponent blok={childBlok} key={childBlok._uid} />
  ));

  return (
    <div {...storyblokEditable(blok)} className="page-body">
      <MetaTags data={blok.seoMeta} />
      {content}
    </div>
  );
};

export default Page;
